<template>
  <b-container>
    <b-card 
      style="max-width: 50rem;margin-left:auto;margin-right:auto;font-family: Arial, Helvetica, sans-serif;"
      border-variant="warning"
      header="Details"
      header-bg-variant="warning"
      header-text-variant="white"
    >
    
     <template #header>
        <h5 class="mb-0">{{subjectNames}} &nbsp;
        </h5>
      </template> 
    <b-card v-for="upload in uploads" :key="upload.num" :title="upload.uploadName" class="mt-2"> 
      <b-embed
        v-if="upload.uploadType=='video'"
        type="iframe"
        aspect="16by9"
        :src="upload.uploadUrl"
        allowfullscreen
      ></b-embed>
       <a v-if="upload.uploadType=='pdf'" :href="upload.uploadUrl" target="_blank" class="mx-3">
           <CIcon name="cil-book"/>
        <!-- <img src="@/assets/Pdf.jpg" style="width:30px" class="img-fluid" /> -->
      </a>
    </b-card>
    </b-card>
  </b-container>
  
</template>
<script>
export default {
    name:'subjectDetails',
  props: ["subjectName"],
  data() {
    return {
        subjectNames:"",
      uploads: [
        {
          num: 1,
          uploadName: "Introduction",
          uploadUrl: "http://msobcfdc.in/downloads/201901311225012722.pdf",
          uploadType: "pdf"
        },
        {
          num: 2,
          uploadName: "Chapter 1",
          uploadUrl: "https://www.youtube.com/embed/zpOULjyy-n8?rel=0",
          uploadType: "video"
        },
        {
          num: 3,
          uploadName: "Chapter 1, Notes",
          uploadUrl: "http://msobcfdc.in/downloads/201901311225012722.pdf",
          uploadType: "pdf"
        },
        {
          num: 4,
          uploadName: "Chapter 2",
          uploadUrl: "https://www.youtube.com/embed/zpOULjyy-n8?rel=0",
          uploadType: "video"
        },
        {
          num: 5,
          uploadName: "Chapter 2, Notes",
          uploadUrl: "http://msobcfdc.in/downloads/201901311225012722.pdf",
          uploadType: "pdf"
        }
      ]
    };
  },
  methods: {},
  mounted() {
      this.subjectNames = this.$route.params.subjectName;
  }
};
</script>